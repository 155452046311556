import React, { useState, useContext } from "react";
import Connect from "./Connect.js";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { AccountInfoContext } from "../Context/AccountInfo.js";
import PackList from "../signatureList/PackListSignature.json";
import "../App.css";

function MintPacks() {
  let accountInfo = useContext(AccountInfoContext);

  const [alert, setAlert] = useState({
    active: false,
    content: null,
    variant: null,
  });

  function displayAlert(message, variant) {
    setAlert({ active: true, content: message, variant: variant });
    setTimeout(function () {
      setAlert({ active: false, content: null, variant: null });
    }, 10000);
  }

  async function findSignedMessage(iterator) {
    let data = null;
    for (let i = 0; i < PackList.length; i++) {
      let key = Object.keys(PackList[i])[0];
      if (parseInt(key) === parseInt(iterator)) {
        data = PackList[i][key];
      }
    }
    console.log(data);
    return data;
  }

  function renderAlert() {
    if (alert.active) {
      return (
        <Col className="m-">
          <br />
          <br />
          <Alert variant={alert.variant}>{alert.content}</Alert>
        </Col>
      );
    }
  }

  async function handleMint() {
    try {
      let iterator = await accountInfo.UnitMintInstance.methods
        .iterator()
        .call();
      console.log(iterator);
      let price = await accountInfo.UnitMintInstance.methods.price().call();
      const data = await findSignedMessage(iterator);
      console.log(data.pack);
      console.log(data.signature.v);
      console.log(data.signature.r);
      console.log(data.signature.s);
      try {
        await accountInfo.UnitMintInstance.methods
          .mint(
            accountInfo.account,
            data.pack,
            data.signature.v,
            data.signature.r,
            data.signature.s
          )
          .send({ from: accountInfo.account, value: price });
      } catch (error) {
        displayAlert(error.message);
      }
    } catch (error) {}
  }

  function renderMintButton() {
    return (
      <Container>
        <Row>
          <Col className="d-flex align-items-left justify-content-center m-2">
            <Button
              variant="light"
              id="mint_button"
              onClick={() => handleMint()}
            >{`Mint`}</Button>
          </Col>
        </Row>
      </Container>
    );
  }

  function renderUserInterface() {
    return (
      <React.Fragment>
        <Row>{renderMintButton()}</Row>
      </React.Fragment>
    );
  }

  return (
    <Container className="mb-5">
      <Row style={{ fontWeight: "bold" }}>
        <Col className="title_font">Void 2122 - Genesis drop</Col>
      </Row>
      <Row style={{ fontWeight: "bold" }}>
        <Col>
          Mint a pack of 6 units from the Genesis collection.
          <br />
          Each pack cost 0.1ETH and will be part of the unique Genesis
          collection.
          <br />
          You can mint as many packs as you want while supply lasts.
        </Col>
      </Row>
      <Row className="xs_center">{renderUserInterface()}</Row>
      <Row className="d-flex xs_center">{renderAlert()}</Row>
      <Connect />
    </Container>
  );
}

export default MintPacks;
